import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames          from 'classnames';
import { useCookies           }  from 'react-cookie';
import { Dispatch         }  from 'redux';
import { useDispatch      }  from 'react-redux';
import   axios            , 
       { AxiosError       }  from 'axios';
import { jwtDecode        }  from "jwt-decode";                // Pas utilisé ici
import { Link             ,
         useNavigate      ,
         redirect         ,
         useLocation      ,
         useSearchParams  }  from 'react-router-dom';
import { format           ,
         parseISO         }  from 'date-fns';

import   styles              from './login.module.scss';

import { initAuthentication,
         setLogin          , 
         setToken          ,
		 setState          }  from '../../redux/authenticationManagment/actionCreators';

import { useTypedSelector }  from '../../hooks/useTypeSelector';
import { store            }  from '../../redux/store';

import { Field            }  from '../widget_field/field'; 
import { Trigger          }  from '../widget_trigger/trigger';

import { Menus                }  from '../bloc_menus/menus';
import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';

import { WindowForgottenPassword }  from '../window_forgotten_password/window_forgotten_password';


import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

         import { BurgerMenu           }  from '../sticky_burger_menu/sticky_burger_menu';


export interface LoginProps {
  className?: string;
}

export const Login = ({ className }: LoginProps) => {
  
  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();
  const location                = useLocation();
    
  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
    
  const [ email                 , setEmail             ] = useState('');
  const [ password              , setPassword          ] = useState('');
  
  const [ showForgottenPassword , setForgottenPassword ] = useState(false);
  const [ showMessageError      , setShowMessageError  ] = useState(0);

  const [ searchParams          , setSearchParams      ] = useSearchParams();
  const [ emailCheck            , setEmailCheck        ] = useState(0);
  const [ emailCheckDate        , setEmailCheckDate    ] = useState('');

  const [ cookies               , setCookie             ] = useCookies(['CookiesConsent']);
  const [ showCookiesParameters , setCookiesParameters  ] = useState(false);
  const [ firstRender           , setFirstRender        ] = useState(true);

   // https://api.lovelightfactory.com/api/verif?signature=qvirRHMllgigpVKxOtXzuBaAD8yCxOLBQnZt0ErdjuPuntO8fk

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }

    if ( authentication.login != "" && 
         authentication.token != "" )
     {
      navigate ("/compte");
     }
     
    if ( emailCheck === 0 )
     {
      const signature = new URLSearchParams(location.search).get("signature");
   
      if ( signature )
       {
        checkEmail(signature!)
       }     
     }      


  }); 
  
  const checkEmail = async (signature: string) => {

    const baseUrl  = `${API_URLS.GetEmailValidation}?signature=`; // "https://api.lovelightfactory.com/api/email_validation?signature=";

    const url = `${baseUrl}${signature}`;

    var retour = -1;

    try {

      const response  = await axios.get(url);
      const statut    = response.status;
      const resultat  = response.data.resultat;
      const dateVerif = response.data.dateVerif;

      if ( statut == 200 && resultat != -1 )
       {
        if ( dateVerif !== undefined )
              {
               retour = 2;
               
               const chaine = format ( parseISO (dateVerif), 'dd/MM/yyyy' );
               
               setEmailCheckDate ( chaine );
              }
         else {
               retour = 1;
              }
       }
    } 
    catch (error) {
    }
   setEmailCheck (retour);
  };

  const checkEmailValidation = async (email: string): Promise<number> => {

    const baseUrl  = `${API_URLS.GetEmailValidationCheck}?email=`; // "https://api.lovelightfactory.com/api/email_validation_check?email=";
    const valeur   =  encodeURIComponent (email);

    const url = `${baseUrl}${valeur}`;

    var retour = -1;

    try {

      const response  = await axios.get(url);
      const statut    = response.status;
      const resultat  = response.data.resultat;
      const dateVerif = response.data.dateVerif;

      if ( statut == 200 && resultat != -1 )
       {
        retour = 1;
       }
    } 
    catch (error) {

      retour = -1;
      
      setShowMessageError ( 1 );
    }

   return retour;
  };


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {

    try {

      const response = await axios.post (

                                                   `${API_URLS.PostAuth}`,

                                   //        'https://api.lovelightfactory.com/auth',

                                          { email    ,
                                            password }
                                         );
                                          
      if ( response.status == 200 )
            {
             dispatch ( setLogin (email) );
             dispatch ( setToken (response.data.token) );
             dispatch ( setState (1) );

             const check = await checkEmailValidation ( email );


             if ( check === 1 )
                   {
                    logConnection ( email , "succes" );

                    navigate("/compte");
                   }
              else {
                    logConnection ( email , "unvalidated" );

                    dispatch ( initAuthentication() );

                    setShowMessageError ( 2 );
                   }


            }
       else {
             logConnection ( email , "autre" );
                  
             setShowMessageError ( 1 )
               }
    } 
    catch (error) {
    
      if (axios.isAxiosError(error) && error.response)
            {
             logConnection ( email ,  error.response.data.message );
            }
       else {
             logConnection ( email , "erreur de connexion" );
            }
    
      setShowMessageError ( 1 )

    }
  };

  const logConnection = async ( login:string , status:string ) => {
  
      const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json'
    };
  
        try 
         {
          const response = await axios.post (
  //                                             'https://api.lovelightfactory.com/api/connections',

                                               `${API_URLS.PostConnection}` ,

                                              { login  ,
                                                status },
                                            
                                             {
                                               headers: headers
                                             }
                                            );
         } 
        catch (error) {
           setShowMessageError ( 1 )

        }
  
  }


  const handleClickOnForgottenPassword = (event: React.MouseEvent<HTMLElement>) => {
    
    event.preventDefault();
        
    setForgottenPassword( true );
  };

  const handleClickOnCloseForgottenPassword = () => {
    
    setForgottenPassword( false );
  };

 const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };

  return (

        <>

              {
                showForgottenPassword &&
                ( <WindowForgottenPassword onClickOnCloseButton = {handleClickOnCloseForgottenPassword }/> )
              }

              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
              
              <img src="/images/LovelightChallenge_1024x1024.png" 
                   className={classNames(styles.vignette, className)} /> 

              <br/>

              { 
                  ( emailCheck == 1 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_ack, className)}>
                      Votre adresse e-mail a été vérifiée
                     </p>
                    </div> )  
              }
			  
              { 
                  ( emailCheck == 2 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_ack, className)}>
                      Votre adresse e-mail a été vérifiée le {(emailCheckDate)}
                     </p>
                    </div> )  
              }
			  
			  { 
                  ( emailCheck == -1 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_error, className)}>
                      Echec de la vérification de l'adresse e-mail
                     </p>
                    </div> )  
              }
    
              <br/>

              <h2 className={classNames(styles.sous_titre, className)}>Espace réservé aux candidats</h2>
    
              <br/>

              <h1 className={classNames(styles.titre, className)}>Authentification</h1>

              <br/>

              <Field name         = "Adresse E-mail"
                     type         = "email"
                     placeholder  = "Entrez votre adresse e-mail"
                     value        = {email}
                     error        = ""
                     onChange     = {handleEmailChange}
                     autocomplete = "on"                          />
               
              <div>
               
                <Field   name         = "Mot de passe"
                         type         = "password"
                         placeholder  = "Entrez votre mot de passe"
                         value        = {password}
                         error        = ""
                         onChange     = {handlePasswordChange}
                         autocomplete = "on"                        />
               
                <Link to="#" onClick = {handleClickOnForgottenPassword} >
                   <p className = {classNames(styles.forgotten_password, className)}>
                      Mot de passe oublié
                   </p>
                </Link>
				
              </div>
               
              <Trigger name        = "Se connecter"
                       link        = "#"
                       onClick     = {handleLogin}
                       className   = {classNames(styles.trigger_login, className)}
                       touch       = "Enter" />

              <br/>
                             
              { 
                  ( showMessageError == 1 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_error, className)}>
                      Erreur d'authentification.
                     </p>
                    </div> )  
              }

              { 
                  ( showMessageError == 2 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_error, className)}>
                      Vous devez valider votre adresse e-mail avant de pouvoir accéder à votre compte.
                     </p>
                    </div> )  
              }

              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>

           
         );


};

export default Login;
