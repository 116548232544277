import   React            ,  
       { useEffect        , 
         useState         ,  
         useCallback      , 
         forwardRef       }  from "react";
import { Link             ,
         useNavigate      }  from 'react-router-dom';
import   classNames          from 'classnames';
import { useCookies       }  from 'react-cookie';
import   Toggle              from "react-native-toggle-element";   //  https://github.com/mymai91/react-native-toggle-element
import { Dispatch         }  from 'redux';
import { useDispatch      }  from 'react-redux';

import { initAuthentication   ,
         setLogin             , 
         setToken             }  from '../../redux/authenticationManagment/actionCreators';
		 
import { initRegistration   }  from '../../redux/registrationManagment/actionCreators';
		 
import { useTypedSelector }  from '../../hooks/useTypeSelector';
import { store            }  from '../../redux/store';
import   styles              from './top_banner_account2.module.scss';

import { Trigger          }  from '../widget_trigger/trigger';

export interface TopBannerAccount2Props {
  className?:         string;                 // classe CSS complémentaire
  firstname:          string;                 // prénom
  lastname:           string;                 // nom
  email:              string;                 // e-mail
  rang:               number;                 // e-mail
  exAequo:            number;                 // e-mail

}

export const TopBannerAccount2 = ({ className         , 
                                   firstname         , 
                                   lastname          , 
                                   email             ,
								   rang              ,
								   exAequo}: TopBannerAccount2Props) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment   );




  return (
  
    <div className={classNames(styles.root, className)}>
 
      <div className={classNames(styles.left, className)}>
             
        <p className={classNames(styles.firstname_lastname, className)}>
          {registration.firstname} {registration.lastname}
        </p>

        <p className={classNames(styles.email, className)}>
          {registration.email}
        </p>

        <p className={classNames(styles.email, className)}>

			   {
			    rang == 0 
                ?
                "Non classé"
                :
                <>
			   {rang}
               <sup>{rang == 1 ? "er" : "ème"}</sup>
			   &nbsp;
			   { exAequo > 0 ? ( exAequo == 1 ? "ex aequo avec un autre participant" : `ex aequo avec  ${exAequo} autres participants` ) : "" }
                </>				
			   }

        </p>
               
      </div>
       
      <div className={classNames(styles.right, className)}>
             
            
             
      </div>
      
    </div>
           
  );
};

export default TopBannerAccount2;
