import   React      , 
       { useEffect  ,
         useState   }  from 'react';
import   classNames    from 'classnames';

import   styles        from './window_photo2.module.scss';

import { Ensemble             }  from '../../redux/voteManagment/actionTypes';		 

import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { store                }  from '../../redux/store';

import { Image                , 
         Gallery              }  from "react-grid-gallery";	

export interface WindowPhoto2Props {
  className?:             string;
  onClickOnCloseButton:   (() => void)
  onClickOnSelectButton?: ((index:number) => void)
  indexParam:              number
  indexes:                number[]
}

export const WindowPhoto2 = ({ className, 
                              onClickOnCloseButton, 
							  onClickOnSelectButton,
							  indexParam  ,
							  indexes }: WindowPhoto2Props) => {

  const { ensemble       } = useTypedSelector ((state) => state.voteManagment   );

  const [ select      , setSelect      ] = useState(ensemble.photos[indexes[indexParam]].selected);

  const [ hoverCroix  , setHoverCroix  ] = useState(false);
  const [ hoverCoeur  , setHoverCoeur  ] = useState(false);
  const [ hoverGauche , setHoverGauche ] = useState(false);
  const [ hoverDroite , setHoverDroite ] = useState(false);

  const [ index       , setIndex       ] = useState(indexParam);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    setSelect (ensemble.photos[indexes[index]].selected);
	
	/*
	 console.log ( "Zoom id="   , ensemble.photos[indexes[index]].id           , 
	               " url="      , ensemble.photos[indexes[index]].urlMagnified ,
				   " select="   , ensemble.photos[indexes[index]].selected     ,
				   " isMobile=" , (isMobile?"mobile":"PC"));
    */
  },[select,index]);
  
  const handleClick = () => {
       onClickOnCloseButton();
  };

  const handleClickSVG = () => {
	   setSelect (!select);
	   
	   if ( typeof (onClickOnSelectButton) !== "undefined" )
	    {
	     onClickOnSelectButton (index??-1);
		}
  };

  const handleHoverCroix = () => {
	   setHoverCroix (true);
  };

  const handleMouseOutCroix = () => {
	   setHoverCroix (false);
  };

  const handleHoverCoeur = () => {
	   setHoverCoeur (true);
  };

  const handleMouseOutCoeur = () => {
	   setHoverCoeur (false);
  };

  const handleHoverGauche = () => {
	   setHoverGauche (true);
  };

  const handleMouseOutGauche = () => {
	   setHoverGauche (false);
  };

  const handleHoverDroite = () => {
	   setHoverDroite (true);
  };

  const handleMouseOutDroite = () => {
	   setHoverDroite (false);
  };

  const decIndex = () => {
   
       var newIndex:number = index - 1;
	   
	   if ( newIndex < 0 )
	    {
		 newIndex=indexes.length-1;
		}
  
	   setIndex (newIndex);
  };

  const incIndex = () => {
   
       var newIndex:number = index + 1;
	   
	   if ( newIndex >= indexes.length )
	    {
		 newIndex=0;
		}
  
	   setIndex (newIndex);
  };

const log = (e: KeyboardEvent): void => {
    console.log(e.key);
  }


 return (

    <div className = { classNames(styles.cadrePhoto, styles.bloc, className) } >

      <div className = { classNames(styles.bloc_ligne_info, className) } >
	   <span className = { classNames(styles.titre, className) }>{ensemble.photos[indexes[index]].name}</span><br/>Crédit photo : {ensemble.photos[indexes[index]].firstname} {ensemble.photos[indexes[index]].lastname}<br/>
	  </div>
	  
      <div className = { classNames(styles.bloc_ligne_photo, className) } >
       <img src       = {ensemble.photos[indexes[index]].urlMagnified} 
            className = { classNames(styles.photo, className) }  
            alt       = "zoom"                                 />
	  </div>
		
      <div className={classNames(styles.divCroix, className)}>

		<svg width="32"
             height="32"
             viewBox="0 0 32 32"
			 onClick = {handleClick}
             onMouseOver={handleHoverCroix}
             onMouseOut={handleMouseOutCroix}
 			 xmlns="http://www.w3.org/2000/svg">

             <circle fill="#FFFFFFFF" cx="15" cy="15" r="15" />
              <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256"
                       fill={(isMobile || (!isMobile && !hoverCroix))?"#000000FF":"#FF0000FF"}
			           transform="scale(0.04)  translate(120, 120)"/>
        </svg>

      </div>



      <div className={classNames(styles.divCoeur, className)}>
		<svg width="32"
             height="32"
             viewBox="0 0 32 32" 
			 onClick = {handleClickSVG}
             onMouseOver={handleHoverCoeur}
             onMouseOut={handleMouseOutCoeur}
			 xmlns="http://www.w3.org/2000/svg">
  
           <circle fill="#FFFFFFFF" cx="15" cy="15" r="15" />
	
	       <path fill= {( (!isMobile && ((!select&&hoverCoeur)||(select&&!hoverCoeur))) ||
		                  (isMobile && select) )?"#FF0000FF":"#000000FF"} d="M13.53014 10.46972C12.13268 9.07226 9.86694 9.07226 8.46948 10.46972C7.07202 11.8672 7.07202 14.13292 8.46948 15.5304L14.99982 22.9276L21.5302 15.53038C22.9276 14.13292 22.9276 11.8672 21.5302 10.46972C20.1326 9.07226 17.86694 9.07226 16.46948 10.46972L14.99982 11.9394L13.53014 10" />
		   
        </svg>
		
      </div>
	  
	  <div className={classNames(styles.divDroite, className)}>

		<svg width="32"
             height="32"
             viewBox="0 0 32 32"
			 onClick = {incIndex}
             onMouseOver={handleHoverDroite}
             onMouseOut={handleMouseOutDroite}
 			 xmlns="http://www.w3.org/2000/svg">

           <circle fill="#FFFFFFFF" cx="15" cy="15" r="15" />
            <path d="M14.5858 5.29291C14.1953 5.68343 14.1953 6.3166 14.5858 6.70712L19.8787 12L14.5858 17.2929C14.1953 17.6834 14.1953 18.3166 14.5858 18.7071L15.2929 19.4142C15.6834 19.8048 16.3166 19.8048 16.7071 19.4142L23.0607 13.0607C23.6464 12.4749 23.6464 11.5251 23.0607 10.9394L16.7071 4.5858C16.3166 4.19528 15.6834 4.19528 15.2929 4.5858L14.5858 5.29291Z"
                  fill={(isMobile || (!isMobile && !hoverDroite))?"#000000FF":"#FF0000FF"}
			      transform="scale(1.5)  translate(-8, -2)"/>

       </svg>

      </div>

	  <div className={classNames(styles.divGauche, className)}>

		<svg width="32"
             height="32"
             viewBox="0 0 32 32"
			 onClick = {decIndex}
             onMouseOver={handleHoverGauche}
             onMouseOut={handleMouseOutGauche}
 			 xmlns="http://www.w3.org/2000/svg">

             <circle fill="#FFFFFFFF" cx="15" cy="15" r="15" />
              <path d="M9.41421 5.29291C9.80474 5.68343 9.80474 6.3166 9.41421 6.70712L4.12132 12L9.41421 17.2929C9.80474 17.6834 9.80474 18.3166 9.41421 18.7071L8.70711 19.4142C8.31658 19.8048 7.68342 19.8048 7.29289 19.4142L0.93934 13.0607C0.353553 12.4749 0.353553 11.5251 0.93934 10.9394L7.29289 4.5858C7.68342 4.19528 8.31658 4.19528 8.70711 4.5858L9.41421 5.29291Z"
                    fill={(isMobile || (!isMobile && !hoverGauche))?"#000000FF":"#FF0000FF"}
			        transform="scale(1.5)  translate(4, -2)"/>
        </svg>

      </div>
		
	</div>
    );

};

export default WindowPhoto2;
